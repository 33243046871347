import React, {
  Dispatch,
  SetStateAction,
  useState,
  ForwardedRef,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PIIchecks } from "./PIIchecks"; // for private information checks
import { LinkedText, LinkedTextLines } from "~/src/components/LinkedText/index";

interface PromptFormProps {
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  sendSubmit: (value: string) => void;
  submitDisabled: boolean;
  isLoading: boolean;
  ref: React.Ref<HTMLInputElement>;
}

export const PromptForm = forwardRef<HTMLInputElement, PromptFormProps>(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [piiWarning, setPiiWarning] = useState<string | null>(null);
    const [otherWarning, setOtherWarning] = useState<string | null>(null);

    // Add PII check function
    const checkForPII = (input: string) => {
      for (const check of PIIchecks) {
        if (check.check(input)) {
          return check.type;
        }
      }
      return null;
    };

    const checkOtherWarning = (input: string) => {
      if (input.length > 250) {
        return "toolong";
      }
      return null;
    };

    // onChange handler
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      props.setInputValue(newValue);
      const piiReason = checkForPII(newValue);
      setPiiWarning(piiReason);
      const otherReason = checkOtherWarning(newValue);
      setOtherWarning(otherReason);
    };

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      if (
        props.submitDisabled ||
        props.isLoading ||
        !!piiWarning ||
        !!otherWarning
      ) {
        return;
      }

      props.sendSubmit(props.inputValue);

      // Blur the input to hide the mobile keyboard
      if (ref && "current" in ref && ref.current) {
        ref.current.blur();
      }
    };

    return (
      <>
        <form className="prompt-form" onSubmit={handleSubmit}>
          <div
            className={classNames("prompt-form__warning", {
              "prompt-form__warning--visible": !!piiWarning,
            })}
            role="alert"
          >
            {t("chat.piicheck.warning")}{" "}
            {piiWarning && (
              <span className="prompt-form__warning-details">
                {t("chat.piicheck.warningDetails", {
                  defaultValue: "",
                  reason: t(`chat.piicheck.${piiWarning}`),
                })}
              </span>
            )}
          </div>
          <div
            className={classNames("prompt-form__warning", {
              "prompt-form__warning--visible": !!otherWarning,
            })}
            role="alert"
          >
            {(otherWarning && (
              <LinkedTextLines
                markdown={t(`chat.otherWarning.${otherWarning}`)}
              />
            )) || <span>&nbsp;</span>}
          </div>

          <label htmlFor="question" className="sr-only">
            {t("chat.question.label")}
          </label>
          <div className="prompt-form__input-wrapper">
            <input
              ref={ref}
              className={classNames("input", "prompt-form__input")}
              placeholder={t("chat.question.placeholder", { defaultValue: "" })}
              value={props.inputValue}
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (
                  // on enter but not if the shift key is pressed
                  event.key === "Enter" &&
                  !event.shiftKey
                ) {
                  if (
                    !document.activeElement?.getAttribute(
                      "aria-activedescendant",
                    )
                  ) {
                    handleSubmit(event);
                  }
                }
              }}
            />

            <button
              type="submit"
              className={classNames(
                "prompt-form__button",
                "button",
                "button--primary",
              )}
              disabled={props.submitDisabled || props.isLoading || !!piiWarning}
            >
              <span className="sr-only">{t("chat.question.buttonText")}</span>
            </button>
          </div>
        </form>
      </>
    );
  },
);
