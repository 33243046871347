import configHugcontactgpt from "../../clients/hugcontactgpt/config.js";
import configLozaerncitygpt from "../../clients/lozaerncitygpt/config.js";

export const ORG_NAME = process.env.ORG_NAME;
export const isLocal = process.env.NODE_ENV === "development";
export const isProd = process.env.ENVIRONMENT === "production";
export const ENV_SHORT = isProd ? "prod" : "dev";
export const HOST_POSTFIX = `.api.${ENV_SHORT}.genai.liip.ch`;
export const USE_PROXY = process.env.USE_PROXY === "true";

export const PUBLISH_URL = isLocal
  ? ""
  : `https://${ORG_NAME}.chatbot-component.${ENV_SHORT}.genai.liip.ch`;

export let DEFAULT_LANGUAGE = null;
export let API_KEY = "";
export let FEEDBACK_MODULES = [];
export let FEEDBACK_SHOW_NEXT_STATE_IF = null;
export let CHAT_MODE = false;
export let API_URL_OVERRIDE = "";
export let INDICATE_MODE = "END_OF_PAGE";

switch (ORG_NAME) {
  case "hugcontactgpt": {
    DEFAULT_LANGUAGE = configHugcontactgpt.DEFAULT_LANGUAGE;
    API_KEY = configHugcontactgpt.API_KEY;
    FEEDBACK_MODULES = isProd
      ? configHugcontactgpt.FEEDBACK_MODULES_PROD
      : configHugcontactgpt.FEEDBACK_MODULES_DEV;
    FEEDBACK_SHOW_NEXT_STATE_IF =
      configHugcontactgpt.FEEDBACK_SHOW_NEXT_STATE_IF;
    CHAT_MODE = configHugcontactgpt.CHAT_MODE;
    API_URL_OVERRIDE = configHugcontactgpt.API_URL_OVERRIDE || "";
    INDICATE_MODE = configHugcontactgpt.INDICATE_MODE;
    break;
  }
  case "lozaerncitygpt": {
    DEFAULT_LANGUAGE = configLozaerncitygpt.DEFAULT_LANGUAGE;
    API_KEY = configLozaerncitygpt.API_KEY;
    FEEDBACK_MODULES = isProd
      ? configLozaerncitygpt.FEEDBACK_MODULES_PROD
      : configLozaerncitygpt.FEEDBACK_MODULES_DEV;
    FEEDBACK_SHOW_NEXT_STATE_IF =
      configLozaerncitygpt.FEEDBACK_SHOW_NEXT_STATE_IF;
    CHAT_MODE = configLozaerncitygpt.CHAT_MODE;
    API_URL_OVERRIDE = configLozaerncitygpt.API_URL_OVERRIDE || "";
    INDICATE_MODE = configLozaerncitygpt.INDICATE_MODE;
    break;
  }
}

export const BACKEND_URL = USE_PROXY
  ? `/api/${ORG_NAME}`
  : API_URL_OVERRIDE || `https://${ORG_NAME}${HOST_POSTFIX}/${ORG_NAME}`;

// see
// deployment/ci/env.sh
// for the environment variables

export const STARTUP_MESSAGE = `
ChatBot-Component
=================
If you encounter any issues, please contact:
https://liip.to/ai

Version: ${process.env.CI_COMMIT_SHORT_SHA} ${process.env.ENVIRONMENT} ${process.env.CI_COMMIT_TIMESTAMP}
`;
