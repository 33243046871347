import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { PromptForm } from "~src/components/PromptForm/index";
import { Spinner } from "~/src/components/Spinner/index";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TypingMessageQueue } from "./TypingMessageQueue";
import { LinkedText, LinkedTextLines } from "~/src/components/LinkedText/index";
import { AccordionSection } from "~src/components/Accordion/index";
import Markdown from "~/src/components/Markdown/index";
import { Feedback } from "~/src/components/Feedback/index";
import { Suggestion } from "../../util/suggestions/suggestions";
import { SourcesDto } from "./helpers";
import { API_KEY, FEEDBACK_MODULES, CHAT_MODE } from "~/src/config/env";
import { ModuleName } from "~/src/components/Feedback/index";
import { BACKEND_URL } from "~src/config/env";
import ExpandDimension from "../ExpandDimension";
interface ChatProps {
  lang: string;
  expanded: boolean;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  userId: string | null;
  cID: string | null;
}

interface Message {
  id: string;
  role: "user" | "assistant";
  content: string;
  sources?: SourcesDto[];
  requestId?: string;
}

export const Chat: React.FC<ChatProps> = ({
  className,
  expanded,
  lang,
  inputRef,
  userId,
  cID,
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [requestId, setRequestId] = useState<string | null>(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [disabledTimeout, setDisabledTimeout] = useState<number | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [sseUri, setSseUri] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [sources, setSources] = useState<SourcesDto[]>([]);
  const [queryResult, setQueryResult] = useState(""); // Holds the assistant's typing message

  // Reference to the TypingMessageQueue
  const typingQueueRef = useRef<TypingMessageQueue | null>(null);

  // Memoize derived values
  const serverConnectionErrorMessage = useMemo(
    () => `\n\n${t("chat.answer.serverConnectionError")}`,
    [t],
  );

  const setNewEventSource = useCallback(
    (queryValue: string) => {
      if (queryValue) {
        const paramsObj = {
          query: queryValue,
          referrer: window.location.pathname || "/",
          key: API_KEY,
          ...(CHAT_MODE && { chat: "true" }),
          r: Math.floor((Date.now() / 1000) % 600).toString(),
          lang,
          ...(userId && { uID: userId }),
          ...(cID && { cID: cID }),
        };

        const source = `${BACKEND_URL}/assistant/sse?${new URLSearchParams(
          paramsObj,
        ).toString()}`;
        setSseUri(source);
      }
    },
    [lang, userId, cID],
  );

  const sendSubmit = (inputValue: string) => {
    if (inputValue.trim() === "") return;

    // if chatmode reset first
    if (!CHAT_MODE) {
      setMessages([]);
      setRequestId(null);
      setQueryResult("");
      setSources([]);
    }

    const fetchData = async () => {
      setSources([]);
      setNewEventSource(inputValue);
    };
    setSubmitDisabled(true);
    setInputValue("");
    setQueryValue(inputValue);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: Math.random().toString(36).slice(2, 11),
        role: "user",
        content: inputValue,
      },
    ]);
    if (disabledTimeout) {
      clearTimeout(disabledTimeout);
    }
    setDisabledTimeout(
      window.setTimeout(() => {
        setSubmitDisabled(false);
      }, 5000),
    );

    fetchData();
  };

  useEffect(() => {
    if (!expanded) {
      setIsLoading(false);
      setSubmitDisabled(false);

      setQueryValue("");
      setInputValue("");

      // reset messages and queryResult
      setMessages([]);
      setRequestId(null);
      setQueryResult("");
      setSources([]);
    }
  }, [expanded]);

  useEffect(() => {
    let linksReceived = false;
    let eventSource: EventSource | null = null;

    if (!sseUri || !queryValue) {
      return;
    }

    setIsLoading(true);
    setSources([]);

    // Initialize TypingMessageQueue
    typingQueueRef.current = new TypingMessageQueue(setQueryResult);

    const parseMessageFromEventSource = (parsedData: Record<string, any>) => {
      try {
        if (parsedData.usedLinks) {
          setSources((prevUsedSources) => [
            ...prevUsedSources,
            parsedData.usedLinks,
          ]);
        }
        if (parsedData.response) {
          const answerEnd = () => {
            setSubmitDisabled(false);
            setIsLoading(false);
            typingQueueRef.current?.setThisIsTheEnd();
          };
          if (parsedData.response === "__THIS_IS_THE_ANSWER_END__") {
            answerEnd();
            return;
          }

          if (parsedData.response === "__THIS_IS_THE_END__") {
            if (eventSource) eventSource.close();
            answerEnd();
            setQueryValue("");
            return;
          }
          if (parsedData.response === "__CLR__") {
            setSubmitDisabled(false);
            typingQueueRef.current?.enqueue(parsedData.response);
            return;
          }

          // Handle normal response chunks
          typingQueueRef.current?.enqueue(parsedData.response);
        }

        if (parsedData.id) {
          setRequestId(parsedData.id);
        }
      } catch (error) {
        console.log(error);
      }
    };

    try {
      eventSource = new EventSource(sseUri);

      eventSource.onmessage = (event: MessageEvent) => {
        const parsedData = JSON.parse(event.data);

        if (parsedData.links) {
          linksReceived = true;
        }
        parseMessageFromEventSource(parsedData);
      };

      eventSource.onerror = (e: Event) => {
        if (!linksReceived) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              id: Math.random().toString(36).slice(2, 11),
              role: "assistant",
              content: serverConnectionErrorMessage,
            },
          ]);
        }
        console.log("SSE Error", e);
        if (eventSource) eventSource.close();
        setQueryValue("");
        setSubmitDisabled(false);
        setIsLoading(false);
      };
    } catch (error) {
      console.log(error);
    }

    return () => {
      if (eventSource) eventSource.close();
      // Clear TypingMessageQueue
      typingQueueRef.current = null;
    };
  }, [queryValue, sseUri, serverConnectionErrorMessage]);

  // When the queryResult changes and the assistant has finished typing, add it to messages
  useEffect(() => {
    if (!isLoading && queryResult) {
      const content = queryResult;
      setQueryResult("");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Math.random().toString(36).slice(2, 11),
          role: "assistant",
          content: content,
          sources: [...sources],
          requestId: requestId || "",
        },
      ]);
      setRequestId(null);
      setSources([]);
    }
  }, [isLoading, queryResult]);

  // Compute submitDisabled based on conditions
  const submitBlocked = inputValue.length === 0 || isLoading || submitDisabled;

  // Type-guarded suggestions
  const result = t("chat.suggestions", { returnObjects: true });
  const suggestions: Suggestion[] = Array.isArray(result)
    ? (result as Suggestion[])
    : [];

  return (
    <div
      className={classNames("chat", className, {
        ["chat--expanded"]: expanded,
        ["chat--has-messages"]: messages.length > 0,
      })}
    >
      <div className="chat__content">
        <div className="chat__spacer">
          {messages.length === 0 ? (
            <>
              <div className="h1">
                <LinkedTextLines markdown={t("mainButton.hello")} />
              </div>
            </>
          ) : (
            <>
              {messages.map((message, index) => (
                <div
                  key={message.id}
                  className={`chat__message chat__message--${message.role}`}
                >
                  {message.role === "user" ? (
                    <div className="chat__question">{message.content}</div>
                  ) : (
                    <>
                      <div className="chat__markdown">
                        <Markdown
                          key={message.id}
                          requestId={message.requestId}
                          markdown={message.content}
                          sources={message.sources}
                        >
                          {
                            // only feedback if it's the last message
                            index === messages.length - 1 && (
                              <Feedback
                                requestId={message.requestId || ""}
                                userId={userId}
                                inputValue={message.content}
                                modules={FEEDBACK_MODULES as ModuleName[]}
                              />
                            )
                          }
                        </Markdown>
                      </div>
                    </>
                  )}
                </div>
              ))}
              {/* Display the typing message */}
              {queryResult && (
                <div className="chat__message chat__message--assistant">
                  <div className="chat__markdown">
                    <Markdown
                      requestId={requestId || ""}
                      markdown={queryResult}
                      sources={sources}
                    >
                      <div className="chat__spinner">
                        <Spinner />
                      </div>
                    </Markdown>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="chat__space-filler"></div>
      <div className="chat__footer">
        <div className="chat__spacer">
          <PromptForm
            ref={inputRef}
            setInputValue={setInputValue}
            inputValue={inputValue}
            sendSubmit={sendSubmit}
            submitDisabled={submitBlocked}
            isLoading={isLoading}
          />

          <ExpandDimension
            expandDirection="y"
            forceExpanded={messages.length !== 0}
          >
            <LinkedText className="chat__legal" markdown={t("chat.legal")} />
          </ExpandDimension>

          {suggestions.length > 0 && (
            <ExpandDimension
              expandDirection="y"
              forceExpanded={messages.length === 0}
            >
              <div className="chat__suggestions">
                {suggestions.map((suggestion: Suggestion) => (
                  <AccordionSection
                    key={suggestion.question}
                    label={suggestion.label}
                    question={suggestion.question}
                    content={suggestion.content}
                    sendSubmit={sendSubmit}
                  ></AccordionSection>
                ))}
              </div>
            </ExpandDimension>
          )}
        </div>
      </div>
    </div>
  );
};
