import { LinkedText, LinkedTextLines } from "~/src/components/LinkedText/index";
import IconInfo from "jsx:~/src/components/_root/assets/info.svg";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface LegalButtonProps {
  expanded: boolean;
}

export const LegalButton: React.FC<LegalButtonProps> = ({ expanded }) => {
  if (!expanded) return null;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className={classNames("legal-button", {
          "legal-button--visible": expanded,
          "legal-button--expanded": open,
        })}
        onClick={() => setOpen(!open)}
      >
        <IconInfo />
      </button>
      <button
        className={classNames("legal-button__bg", {
          "legal-button__bg--expanded": open,
        })}
        onClick={() => setOpen(false)}
      ></button>
      <div
        className={classNames("legal-button__text", {
          "legal-button__text--expanded": open,
        })}
      >
        <LinkedText markdown={t("chat.intro")} />
      </div>
    </>
  );
};
