import React, { useEffect, useState, useRef, useCallback } from "react";
import { settings } from "~/src/config/settings";
import { BACKEND_URL } from "~src/config/env";
import classNames from "classnames";

// components
import { MainButton } from "~/src/components/MainButton/index";
import ExpandDimension from "~/src/components/ExpandDimension/index";
import { Chat } from "~/src/components/Chat/index";

const MAX_RETRIES = 5; // Maximum number of retries for fetching user data

interface RootProps {
  lang: string | null;
}

export const Root: React.FC<RootProps> = ({ lang }) => {
  const [userId, setUserId] = useState<string | null>(
    localStorage.getItem("uID") || sessionStorage.getItem("uID"),
  );
  const [cID, setCId] = useState<string | null>(null);

  const [expanded, setExpanded] = useState(settings.expanded); // is the chat visible
  const [loading, setLoading] = useState(false); // Loading state for fetching user data
  const [hovered, setHovered] = useState(false); // Track hover state
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts

  const inputRef = useRef<HTMLInputElement>(null); // Create the ref for the input

  const fetchUserData = useCallback(() => {
    if (loading || retryCount > MAX_RETRIES || (userId && cID)) return; // Stop if loading, retries exceeded, or data is already fetched
    setLoading(true);

    fetch(`${BACKEND_URL}/log`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        referrer: document.referrer || null,
        initialQuery: null,
        uID: userId,
      }),
    })
      .then(async (response) => {
        const user = await response.json();
        if (user && user.id) {
          localStorage.setItem("uID", user.id);
          setUserId(user.id);
          setCId(user.cID);
        } else {
          throw new Error("Invalid user data");
        }
      })
      .catch((error) => {
        console.error("Error while fetching logging id", error);
        setRetryCount((prev) => prev + 1); // Increment retry count on failure
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, cID, loading, retryCount]);

  useEffect(() => {
    // Disable body scroll when chat is expanded
    if (expanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [expanded]);

  useEffect(() => {
    // Trigger fetching user data on hover or expand
    if ((hovered || expanded) && !loading && retryCount <= MAX_RETRIES) {
      fetchUserData();
    }
  }, [hovered, expanded, fetchUserData, retryCount]);

  // Retry mechanism: Retry if fetch fails and retries are remaining
  useEffect(() => {
    if (retryCount > 0 && retryCount <= MAX_RETRIES) {
      const retryTimer = setTimeout(() => {
        fetchUserData();
      }, 500);

      return () => clearTimeout(retryTimer); // Cleanup timeout on unmount
    }
  }, [retryCount, fetchUserData]);

  if (!lang) {
    return (
      <div className="root__overlay">
        <h1>Setup Error!</h1>
        <p>Please set lang in component</p>
      </div>
    );
  }

  const closeModal = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    setExpanded(false);
    setHovered(false);
  };

  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setExpanded(true);
  };

  return (
    <div
      className={classNames("root", { ["root--is-expanded"]: expanded })}
      onMouseEnter={() => setHovered(true)} // Track hover state
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={classNames("root__background", {
          ["root__background--expanded"]: expanded,
        })}
        onClick={closeModal}
      ></div>
      <div className="root__content">
        <MainButton
          expanded={expanded}
          openModal={openModal}
          inputRef={inputRef}
          closeModal={closeModal}
        />

        <div
          className={classNames("root__chat", {
            ["root__chat--expanded"]: expanded,
          })}
        >
          <ExpandDimension
            forceExpanded={expanded && !loading} // Only force expanded when not loading
            className="root__expand"
          >
            <Chat
              expanded={expanded}
              lang={lang}
              inputRef={inputRef} // Pass inputRef to PromptForm via Chat
              userId={userId}
              cID={cID}
            />
          </ExpandDimension>
        </div>
      </div>
    </div>
  );
};
