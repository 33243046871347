import { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { SourcesDto } from "~/src/components/Chat/helpers";
import { getTitle } from "~/src/util/getTitle";
import { splitArrayByIndexes } from "./splitArrayByIndexes";

enum SearchResultType {
  PDF = "pdf",
}

const renderLink = (result: SourcesDto) => {
  switch (result.type) {
    case SearchResultType.PDF:
      return (
        <a
          className="sources__link"
          href={result.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sources__prefix" />
          <span className="sources__link-title">{result.title}</span>
        </a>
      );
    default:
      return (
        <a className="sources__link" href={result.url} target="_blank">
          <span className="sources__prefix" />
          <span className="sources__link-title">{getTitle(result?.title)}</span>
        </a>
      );
  }
};

interface SourcesProps {
  sources: SourcesDto[];
  usedSources: string[];
}

const Sources: React.FC<SourcesProps> = ({ sources, usedSources }) => {
  const { t } = useTranslation();

  const { excluded, included } = useMemo(() => {
    return splitArrayByIndexes(
      sources,
      usedSources.map((index) => parseInt(index, 10)),
    );
  }, [sources, usedSources]);

  if (!sources.length) {
    return null;
  }

  return (
    <div className="sources">
      <div className={classNames("sources__box")}>
        <div className="sources__title">
          {t("chat.answer.usedSourcesTitle")}
        </div>
        <ol className="sources__list">
          {included.map((result, index) => (
            <li key={index} className="sources__list-item">
              {renderLink(result)}
            </li>
          ))}
          {excluded.slice(0, 3).map((result, index) => (
            <li key={index} className="sources__list-item">
              {renderLink(result)}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Sources;
