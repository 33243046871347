import React, { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { chatRenderers } from "./chatRenderers";
import { fixLLMMarkdown } from "./fixLLMMarkdown";
import ReactMarkdown from "react-markdown";
import "property-information"; // Workaround for dev-build; see https://github.com/remarkjs/react-markdown/issues/747
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeExternalLinks from "rehype-external-links";
import llmFootnote from "./plugins/footnotePlugin";
import combineFootnotesPlugin from "./plugins/combineFootnotesPlugin";
import filterFootnotesPlugin from "./plugins/filterFootnotesPlugin";
import { SourcesDto } from "~/src/components/Chat/helpers";
import Sources from "~src/components/Sources/index";

const ParagraphMarkdown = (props: {
  keyString: string;
  markdown: string;
  parentRef: React.RefObject<HTMLDivElement>;
  sources?: SourcesDto[];
  usedSources?: string[];
  onFootnotesCollected: (data: string[]) => void; // Function prop with data
}) => {
  const components = chatRenderers(
    props.sources || [],
    props.usedSources || [],
    props.parentRef,
  );
  // Ref to collect used sources during render
  const usedSourcesRef = useRef<string[]>([]);

  // Collect used sources but don't update the state yet
  const handleFootnotesCollected = (data: string[]) => {
    usedSourcesRef.current = data;
    props.onFootnotesCollected(data); // Pass the data to the parent
  };

  return (
    <ReactMarkdown
      key={props.keyString}
      remarkPlugins={[
        remarkGfm,
        remarkBreaks,
        llmFootnote,
        combineFootnotesPlugin,
        [
          filterFootnotesPlugin,
          {
            allowedFootnotes: (props.sources || [])
              .filter((result) => result.considered)
              .map((_, index) => `${index + 1}`),
            callback: handleFootnotesCollected,
          },
        ],
      ]}
      rehypePlugins={[
        [rehypeExternalLinks, { rel: ["noreferrer"], target: "_blank" }],
      ]}
      components={components}
    >
      {props.markdown}
    </ReactMarkdown>
  );
};

interface ChatMarkdownProps {
  markdown: string;
  requestId?: string;
  sources?: SourcesDto[];
  children?: React.ReactNode;
}

const ChatMarkdown = React.memo((props: ChatMarkdownProps) => {
  const markdownContainerRef = useRef<HTMLDivElement>(null);
  const [usedSources, setUsedSources] = useState<string[]>([]);
  // Ref to track how many paragraphs have been rendered and to collect the data
  const collectedDataRef = useRef<string[]>([]);

  // Split the markdown into paragraphs
  const paragraphs = React.useMemo(() => {
    return props.markdown
      .split("\n\n") // split by paragraphs
      .map((p) => fixLLMMarkdown(p).trim()) // fix typical LLM markdown issues
      .filter((p) => p.length > 0) // remove empty paragraphs
      .map((content, index) => {
        const id = `${index}_${content.length}_${content.slice(-20)}`; // poor man's unique key
        return { id, content };
      });
  }, [props.markdown]);

  // Function to be called when each paragraph finishes rendering
  const handleFootnotesCollected = (data: string[]) => {
    if (data.length > 0) {
      // check if the data is already in the array
      const newData = data.filter(
        (item) => !collectedDataRef.current.includes(item),
      );
      // if it's not, add it to the array
      if (newData.length > 0) {
        collectedDataRef.current.push(...newData);
      }
    }
  };

  // Effect to update sources after all paragraphs have rendered
  useEffect(() => {
    setUsedSources(collectedDataRef.current);
  }, [paragraphs]);

  return (
    <ErrorBoundary
      fallback={
        <div className="markdown__error">
          Something went wrong. Please try turning off automatic translation via
          Google Chrome, if you have it enabled!
        </div>
      }
    >
      <div ref={markdownContainerRef} className="markdown">
        {paragraphs.map(({ id, content }) => (
          <ParagraphMarkdown
            key={id}
            keyString={`s_${id}`}
            markdown={content}
            parentRef={markdownContainerRef}
            sources={props.sources}
            usedSources={usedSources}
            onFootnotesCollected={handleFootnotesCollected} // Pass the function prop
          />
        ))}
      </div>
      {props.children}
      {props.sources && props.sources.length > 0 && (
        <Sources sources={props.sources} usedSources={usedSources} />
      )}
    </ErrorBoundary>
  );
});

export default ChatMarkdown;
