const config = {
  DEFAULT_LANGUAGE: "de",
  API_KEY: "X9hL4Gp5W2D7eRtF", // this is the public key for the client (no secret key)
  API_URL_OVERRIDE:
    "https://lozaerncitygpt.api.prod.genai.liip.ch/lozaerncitygpt", // we don't have the dev envoirement anymore
  FEEDBACK_MODULES_PROD: ["vote"],
  FEEDBACK_MODULES_DEV: ["vote"], // ["classification", "form"],
  FEEDBACK_SHOW_NEXT_STATE_IF: "VOTE:NEGATIVE",
  CHAT_MODE: false,
  INDICATE_MODE: "ON_SCROLL",
};

export default config;
