import React, { FC, useState, MouseEvent } from "react";
import { IconPlusMinus } from "~src/components/IconPlusMinus/index";
import ExpandDimension from "~/src/components/ExpandDimension/index";
import classNames from "classnames";
import { Suggestion } from "../../util/suggestions/suggestions";

interface AccordionSectionProps {
  label: string | null;
  question: string;
  content?: Suggestion[];
  sendSubmit: (inputValue: string) => void;
}

export const AccordionSection: FC<AccordionSectionProps> = ({
  label,
  question,
  content,
  sendSubmit,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="accordion">
      {content && content?.length > 0 ? (
        <>
          <button
            className={classNames("accordion__header", {
              ["accordion__header--is-expanded"]: expanded,
            })}
            onClick={handleToggle}
            aria-expanded={expanded}
          >
            <div className="h2">{label || question}</div>
            <IconPlusMinus expanded={expanded} />
          </button>
          <ExpandDimension expandDirection="y" forceExpanded={expanded}>
            <div className="accordion__content">
              <ul>
                {content?.map((item: Suggestion) => (
                  <li key={item.question}>
                    <AccordionItem
                      title={item.label || item.question}
                      sendSubmit={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        sendSubmit(item.question);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </ExpandDimension>
        </>
      ) : (
        <button
          className="accordion__header"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            sendSubmit(question);
          }}
        >
          <div className="h2 accordion__link-title">{label || question}</div>
        </button>
      )}
    </div>
  );
};

interface AccordionItemProps {
  title: string;
  sendSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  sendSubmit,
}) => {
  return (
    <button className="accordion__header" onClick={sendSubmit}>
      <span className="accordion__link-title">{title}</span>
    </button>
  );
};
