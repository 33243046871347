import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import { LegalButton } from "~/src/components/LegalButton/index";
import { LinkedText } from "~/src/components/LinkedText/index";
import IconCross from "jsx:~/src/components/_root/assets/cross.svg";
import ExpandDimension from "~/src/components/ExpandDimension/index";
import { INDICATE_MODE } from "~/src/config/env";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface MainButtonProps {
  expanded: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  openModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MainButton: React.FC<MainButtonProps> = ({
  expanded,
  inputRef,
  openModal,
  closeModal,
}) => {
  const [showIndication, setShowIndication] = useState(false);
  const [hasTriggered, setHasTriggered] = useState(false);
  const initialScrollPosition = useRef(window.scrollY);
  const { t } = useTranslation();

  useEffect(() => {
    // Only run scroll detection on screens wider than 800px
    const isWideScreen = () =>
      INDICATE_MODE === "END_OF_PAGE" ||
      window.matchMedia("(min-width: 800px)").matches;

    if (!isWideScreen()) return;

    // Basic scroll detection at the bottom of the page
    const handleOnEndOfPage = () => {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 200
      ) {
        setShowIndication(true);
      } else {
        setShowIndication(false);
      }
    };

    // Advanced scroll detection based on the initial scroll position
    let timeoutId: NodeJS.Timeout;
    const handleOnCertainScroll = () => {
      if (Math.abs(window.scrollY - initialScrollPosition.current) > 300) {
        setShowIndication(true);
        setHasTriggered(true);

        timeoutId = setTimeout(() => {
          setShowIndication(false);
        }, 6000);

        window.removeEventListener("scroll", handleOnCertainScroll);
      }
    };

    // Choose which scroll handler to use based on env variable
    const scrollHandler =
      INDICATE_MODE === "ON_SCROLL" ? handleOnCertainScroll : handleOnEndOfPage;

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [hasTriggered]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    openModal(e);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const title =
    expanded || showIndication
      ? t("mainButton.titleOpen", { defaultValue: "" }) ||
        t("mainButton.title", { defaultValue: "" })
      : t("mainButton.title", { defaultValue: "" });

  return (
    <div
      className={classNames("main-button", {
        "main-button--expanded": expanded || showIndication,
      })}
    >
      <button
        className={classNames([
          "expand-dimension__group-hover",
          "main-button__expand",
        ])}
        onClick={handleClick}
      >
        <div className="main-button__content">
          <div className="main-button__icon"></div>

          <div className="main-button__info">
            {title && <div className="h3 main-button__title">{title}</div>}

            <ExpandDimension forceExpanded={expanded || showIndication}>
              <div
                className={classNames("main-button__text", {
                  "main-button__text--expanded": expanded,
                })}
              >
                <LinkedText markdown={t("mainButton.hello")} />
                <div className="main-button__spacing">
                  <div className="button button--primary">
                    <span>{t("mainButton.buttonText")}</span>
                  </div>
                </div>
              </div>
            </ExpandDimension>
          </div>
        </div>
      </button>
      <LegalButton expanded={expanded} />
      <button
        className={classNames("main-button__close", {
          "main-button__close--expanded": expanded,
        })}
        onClick={closeModal}
      >
        <div className="main-button__close-icon">
          <IconCross />
        </div>
      </button>
    </div>
  );
};
