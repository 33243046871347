import React from "react";
import { useTranslation } from "react-i18next";
import { ModuleName } from "~/src/components/Feedback/index";
import { BACKEND_URL } from "~src/config/env";

interface VoteModuleProps {
  requestId: string | null;
  setModules: React.Dispatch<React.SetStateAction<ModuleName[]>>;
  setStatusMessage: React.Dispatch<React.SetStateAction<string>>;
}

enum FeedbackVote {
  Positive = "positive",
  Negative = "negative",
}

export const VoteModule: React.FC<VoteModuleProps> = ({
  requestId,
  setModules,
  setStatusMessage,
}) => {
  const { t } = useTranslation();

  const handleVote = async (vote: FeedbackVote) => {
    try {
      const body = {
        feedback: vote,
        rID: requestId || "",
      };

      const response = await fetch(`${BACKEND_URL}/quickfeedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.text();
      if (data !== "OK") {
        throw new Error("Quick feedback submission failed");
      }

      if (vote === FeedbackVote.Positive) {
        setStatusMessage("VOTE:POSITIVE");
        setModules((prevModules) => ["classification"]);
      } else {
        setStatusMessage("VOTE:NEGATIVE");
        setModules((prevModules) => ["form"]);
      }
    } catch (error) {
      console.error("Error sending quick feedback:", error);
      setStatusMessage("ERROR");
    }
  };

  const negativeHover = t("feedback.vote.negative", {
    defaultValue: "",
  });
  const positiveHover = t("feedback.vote.positive", {
    defaultValue: "",
  });
  const title = t("feedback.vote.title", {
    defaultValue: "",
  });

  return (
    <div className="feedback__vote">
      {title && <div className="feedback__vote-title">{title}</div>}
      <div className="feedback__vote-interaction">
        <button
          className="feedback__icon feedback__icon--positive"
          onClick={() => handleVote(FeedbackVote.Positive)}
        >
          {positiveHover && (
            <span className="feedback__icon-text">{positiveHover}</span>
          )}
          <span className="feedback__icon-label">
            {t("feedback.vote.positiveLabel", {
              defaultValue: "",
            })}
          </span>
        </button>
        <button
          className="feedback__icon feedback__icon--negative"
          onClick={() => handleVote(FeedbackVote.Negative)}
        >
          {negativeHover && (
            <span className="feedback__icon-text">{negativeHover}</span>
          )}
          <span className="feedback__icon-label">
            {t("feedback.vote.negativeLabel", {
              defaultValue: "",
            })}
          </span>
        </button>
      </div>
    </div>
  );
};
