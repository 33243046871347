import React from "react";

type LinkedTextProps = {
  markdown: string;
  className?: string;
};

type LinkedTextLinesProps = {
  markdown: string;
};

type LinkedTextLineProps = {
  markdown: string;
};

// Function to split the markdown into paragraphs
const splitIntoParagraphs = (markdown: string): string[] => {
  return markdown
    .replaceAll("|||", "\n\n") // Custom delimiter for paragraphs
    .split("\n\n"); // Split into paragraphs by double new lines
};

// Function to parse inline links within a string
const parseLinks = (text: string): (string | JSX.Element)[] => {
  const linkRegex = /\[([^\]]+)\]\(((?:https?|tel|mailto):[^\s)]+)\)/g;
  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;
  let match;

  // Iterate over all matches in the text for links
  while ((match = linkRegex.exec(text)) !== null) {
    // Add the text before the link
    parts.push(text.slice(lastIndex, match.index));

    // Add the link as an <a> element
    parts.push(
      <a
        key={match.index}
        href={match[2]}
        className="a--cta a--underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {match[1]}
      </a>,
    );
    lastIndex = linkRegex.lastIndex; // Update last index to the end of the match
  }

  // Add the remaining text after the last link
  parts.push(text.slice(lastIndex));

  return parts;
};

// Component to handle individual lines, including splitting and parsing links
export const LinkedTextLines: React.FC<LinkedTextLinesProps> = ({
  markdown,
}) => {
  // Replace custom delimiters and split the paragraph into lines
  const lines = markdown
    .replaceAll("||", "  \n") // Custom delimiter for new lines
    .split(/ {2,}\n/); // Split by line breaks

  return (
    <>
      {lines.map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          <LinkedTextLine markdown={line} />
          {lineIndex < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

// handle one line
export const LinkedTextLine: React.FC<LinkedTextLineProps> = ({ markdown }) => {
  // First process bold text
  const processBoldText = (input: string) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = boldRegex.exec(input)) !== null) {
      // Add text before the bold part
      if (match.index > lastIndex) {
        parts.push(input.slice(lastIndex, match.index));
      }
      // Add the bold text wrapped in styled span
      parts.push(
        <span key={match.index} className="linked-text--emphasis">
          {match[1]}
        </span>,
      );
      lastIndex = match.index + match[0].length;
    }
    // Add remaining text
    if (lastIndex < input.length) {
      parts.push(input.slice(lastIndex));
    }
    return parts;
  };

  // Process links after bold text
  const processedBoldText = processBoldText(markdown);
  const processedContent = processedBoldText.map((part, index) => {
    if (typeof part === "string") {
      return parseLinks(part);
    }
    return part;
  });

  return <>{processedContent}</>;
};

export const LinkedText: React.FC<LinkedTextProps> = ({
  markdown,
  className,
}) => {
  const paragraphs = splitIntoParagraphs(markdown);

  return (
    <div className={className}>
      {paragraphs.map((text, index) => (
        <p key={index}>
          {/* Render LinkedTextLines component for each paragraph */}
          <LinkedTextLines markdown={text} />
        </p>
      ))}
    </div>
  );
};
