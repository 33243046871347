const MIN_LONG_NUMBER_LENGTH = 9;

export const PIIchecks = [
  {
    // Email address
    type: "email",
    check: (input: string) => {
      const pattern = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/;
      return pattern.test(input);
    },
  },
  {
    // Credit card number
    type: "creditCard",
    check: (input: string) => {
      const pattern = /\b(?:\d[ -]*?){13,16}\b/;
      return pattern.test(input);
    },
  },
  {
    // Swiss AHV number
    type: "ahv",
    check: (input: string) => {
      const pattern = /\b756\.\d{4}\.\d{4}\.\d{2}\b/;
      return pattern.test(input);
    },
  },
  {
    // Swiss style phone number (starts with 0)
    type: "phoneSwiss",
    check: (input: string) => {
      // Explanation:
      // \b0            - starts with '0' at a word boundary
      // (?:\s*\d){2}   - two digits, possibly with spaces in between (e.g. "0 7 9")
      // (?:\s*\/\s*)?  - optional slash, possibly surrounded by spaces
      // (?:\s*\d){7,}  - at least seven more digits, each possibly preceded by spaces
      const pattern = /\b0(?:\s*\d){2}(?:\s*\/\s*)?(?:\s*\d){7,}\b/;
      return pattern.test(input);
    },
  },
  {
    // International phone number (starts with + or 00)
    type: "phoneInternational",
    check: (input: string) => {
      // Explanation:
      // (?:\+|00)         - starts with '+' or '00'
      // (?:\s*\d){10,}    - at least ten digits, allowing spaces before each digit
      const pattern = /(?:\+|00)(?:\s*\d){10,}\b/;
      return pattern.test(input);
    },
  },
  {
    // Swiss Passport number
    type: "swissPassport",
    check: (input: string) => {
      // starting with a letter (excluding I, O), followed by 7 alphanumeric chars including at least one digit.
      const pattern = /\b[A-HJ-NP-Z](?=.*\d)[A-HJ-NP-Z0-9]{7}\b/;
      return pattern.test(input);
    },
  },
  {
    // Numbers longer than 8 digits
    type: "longNumber",
    check: (input: string) => {
      const pattern = /\b\d{9,}\b/;
      return pattern.test(input);
    },
  },
];
